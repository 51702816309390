function startTimer() {
	const daysElement = document.getElementById("days");
	const hoursElement = document.getElementById("hours");
	const minutesElement = document.getElementById("minutes");
	const secondsElement = document.getElementById("seconds");
	const daysElement2 = document.getElementById("days2");
	const hoursElement2 = document.getElementById("hours2");
	const minutesElement2 = document.getElementById("minutes2");
	const secondsElement2 = document.getElementById("seconds2");

	function updateTimer() {
		const now = new Date();
		const utcMidnight = new Date(
			Date.UTC(
				now.getUTCFullYear(),
				now.getUTCMonth(),
				now.getUTCDate(),
				0,
				0,
				0,
				0
			)
		);
		const tomorrowMidnight = new Date(
			utcMidnight.getTime() + 7 * 24 * 60 * 60 * 1000
		);

		const timeLeft = tomorrowMidnight - now;

		const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
		const hours = Math.floor(
			(timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
		);
		const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
		const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

		if (daysElement) {
			daysElement.innerText = days;
			hoursElement.innerText = hours;
			minutesElement.innerText = minutes;
			secondsElement.innerText = seconds;
			daysElement2.innerText = days;
			hoursElement2.innerText = hours;
			minutesElement2.innerText = minutes;
			secondsElement2.innerText = seconds;
		}
	}

	updateTimer();
	setInterval(updateTimer, 1000);
}

startTimer();
