const swiperCasino = new Swiper(".js-horScroll-casino", {
	slidesPerView: "3",
	spaceBetween: 24,
	loop: true,
	navigation: {
		nextEl: ".casino-sports__casino__btn.swiper-button-next",
		prevEl: ".casino-sports__casino__btn.swiper-button-prev",
	},
	breakpoints: {
		319: {
			slidesPerView: "auto",
			spaceBetween: 10,
		},
		1024: {
			slidesPerView: 3,
			spaceBetween: 24,
		},
	},
});

const swiperSports = new Swiper(".js-horScroll-sports", {
	slidesPerView: "3",
	spaceBetween: 24,
	loop: true,
	navigation: {
		nextEl: ".casino-sports__sports__btn.swiper-button-next",
		prevEl: ".casino-sports__sports__btn.swiper-button-prev",
	},
	breakpoints: {
		319: {
			slidesPerView: "auto",
			spaceBetween: 10,
		},
		1024: {
			slidesPerView: 3,
			spaceBetween: 24,
		},
	},
});
