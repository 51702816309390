const swiperProducts = new Swiper(".js-horScroll-reviews", {
	slidesPerView: "2",
	spaceBetween: 24,
	navigation: {
		nextEl: ".reviews__btn.swiper-button-next",
		prevEl: ".reviews__btn.swiper-button-prev",
	},
	breakpoints: {
		319: {
			slidesPerView: "auto",
			spaceBetween: 10,
		},
		1024: {
			slidesPerView: 2,
			spaceBetween: 24,
		},
	},
});
